body {
  font-family:"Open Sans", "Helvetica Light", "Helvetica Neue",Helvetica,Arial,sans-serif;
}
h5, h4 {
  font-weight:bold;
}

iframe {
  max-width:100%;
}

#content {
  min-height: 65vh;
}

.navbar .navbar-header .navbar-brand{
  background: url(../images/helioscope_84.png) no-repeat 0 0;
  background-size: 228px 42px;
  width:228px;
  height:42px;
  margin-top: 5px;
  margin-bottom:5px;
  margin-left: 20px !important;
  top:2px;
}
.red-navbar {
    background-color: #841E1E !important;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  color: #ffffff;
  background-color: inherit;
}

.second_nav {
    float:right;
    right:10px;
    top:0;
}

.shortcuts .shortcut {
    width:100px;
}

.pager {
  margin:5px 0;
}
hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #BBB;
  border-bottom: 1px solid #CCC;
}
/*
#loading { display:none;
           position:fixed;
           left:0;
           top:0;
           width:100%;
           height:100%;
           background-image:url("../img/transparent_bg.png");
           z-index:2000;

}
#loading p {
    position:absolute;
    color:#FFF;
    top:50%;
    left:50%;
    margin-left:-40px;
    margin-top:-10px;
    text-align:center;
}
*/

.subnavbar {
  margin-bottom:1.5em;
}

.subnavbar li.active .caret {
    opacity: 1;
    color:#f90;
    border-top-color: #f90;
    border-bottom-color: #f90;
}

.star-column > a:hover, a.star-column:hover {
    color:#F80;
    text-decoration:none;
    text-shadow: 0 0 6px;
}
.star-column, .star-column > a, th.star-column, td.star-column, .widget .widget-header .star-column{
    text-align: center;
    color:#FB0;
}


.simulation-table .rotate {
    -webkit-transform: rotate(-90deg);/* Safari */
    -moz-transform: rotate(-90deg);/* Firefox */
    -ms-transform: rotate(-90deg);/* IE */
    -o-transform: rotate(-90deg);/* Opera */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);/* Internet Explorer */
    transform: rotate(-90deg);
}

.simulation-table th.header-cell {
    font-size:14px;
}

.simulation-table th {
        text-transform: none;
        font-size:12px;
        vertical-align:middle;
        border-right: 1px solid #dddddd;
}

.simulation_table thead tr {
    text-align:center;
}

.simulation-table td {
        text-transform: none;
        font-size:12px;
        vertical-align:middle;
        text-align:center;
        background-color: white;

}

.simulation-table .progress {
    margin:0;
}

.table-bordered thead tr th,
.simulation_table.table-bordered thead tr th,
.table-horizontal tbody tr th,
.simulation-table tbody tr th {
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 12px;
  font-weight: 600;
  color: #444;
  /*border-left: 1px solid #F1F1F1;*/
  /*border-right: 1px solid #CCC;*/
  -webkit-box-shadow: inset 0 1px 0 #ffffff;
  -moz-box-shadow: inset 0 1px 0 #ffffff;
  box-shadow: inset 0 1px 0 #ffffff;
  background-color: #e6e6e6;
  background-image: -moz-linear-gradient(top, #eeeeee, #dadada);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#eeeeee), to(#dadada));
  background-image: -webkit-linear-gradient(top, #eeeeee, #dadada);
  background-image: -o-linear-gradient(top, #eeeeee, #dadada);
  background-image: linear-gradient(to bottom, #eeeeee, #dadada);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeeeeee', endColorstr='#ffdadada', GradientType=0);
}

.simulation-table tbody tr th:first-child {
  border-left-color: #CCC;
}
.simulation-table tbody tr th:last-child {
  border-right: none;
}

td.inner-table {
  padding:0 !important;
  margin:0;
}

td.inner-table > table {
  margin:0;
  padding:0;
  width:100%;
}

.inner-table .table > tbody > tr:first-child > th,
.inner-table .table > tbody > tr:first-child > td {
    border-top: none!important;
}
.inner-table .table > tbody > tr:last-child > th,
.inner-table .table > tbody > tr:last-child > td {
    border-bottom: none!important;
}

.inner-table .table > tbody > tr > td:first-child {
    border-left:none!important;
}
.inner-table .table > tbody > tr > td:last-child {
    border-left:none!important;
}


th.dense-cell, td.dense-cell {
  padding-top:0px;
  padding: 1px;
  text-align:center;
}

.widget.widget-lesspad .widget-content {
  padding:5px;
}

.widget-header-control {
    margin-left: 8px;
    margin-top:1px;
    margin-right: 8px;
}

.widget-header-control > .btn [class^="fa-"],
.widget-header-control > .btn [class*=" fa-"] {
  margin-left: -6px;
  margin-top: -4px;
  color:inherit;
}

.widget-header-control .btn {
  margin-top:-6px;
  height: 32px;
}

.widget-header-control .form-inline,
.widget-header-control form {
  margin-top:-4px;
  height: 34px;
}
/*
.widget-header-control form {
    margin:0;
}
*/

.breadcrumb-row {
    position: absolute;
    margin-top:-32px;
}
.breadcrumb {

    background-color: transparent;
}


/* dropdown arrow on the right side */

.left-menu {
    left:-90%;
}

.subnavbar .dropdown > .dropdown-menu.left-menu::after {
    left:160px;
}


/* Pagination fixes */

td .pagination {
  margin:0;
}

ul.pagination li a {
    margin-right: 0;
    margin-left: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    cursor: hand; cursor: pointer;
}
ul.pagination li:first-child a,
ul.pagination li:last-child a {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

ul.pagination li:first-child > a,
ul.pagination li:first-child > span {
  border-left-width: 1px;
  -webkit-border-bottom-left-radius: 4px;
          border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
          border-top-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topleft: 4px;
}


ul.pagination li:last-child > a,
ul.pagination li:last-child > span {
  -webkit-border-top-right-radius: 4px;
          border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
          border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
}


/* Application Styles */
.account-container {
  margin: 0 auto;
}


/* correct table spacing */
.table.table-bordered.table-condensed tbody tr td {
  padding-top: 2px;
  padding-bottom: 2px;
}




/* Pines Notify Styles*/
.ui-pnotify-icon, .ui-pnotify-icon span {
  padding-top:2px;
}



.table-bordered thead tr th {
  border-left:none;
}

.clickable {
  cursor: hand !important;
  cursor: pointer !important;
}


.wave-left-hide, .wave-left-show.wave-left-show-active,
.wave-right-hide, .wave-right-show.wave-right-show-active {
  left:inherit;
  margin-right:inherit;
  right:inherit;
  margin-left:inherit;
}

/*New Scenario CSS*/
tr.active {
  background-color: #FC3 !important;
}
tr.active td {
  border-left: 1px solid #FC3 !important;
  background-color: #FC3 !important;
}

.new-scenario h3, .new-scenario h4 {
  line-height:inherit;
  border-bottom:1px #F5F5F5 solid;
}

.row-divider {
  margin-bottom: 1.5em;
  border: 0;
  border-bottom:1px #F5F5F5 solid;
}


.summary > td {
  background-color:#FFE0B2 !important;
  font-size:105%;
  font-weight:bold;
  border-bottom:1px solid #CCC
}

.number-table td {
  text-align: right;
}

.navbar-inverse  h3{
  font-family: "Helvetica Light", Helvetica, Arial, sans-serif;
  font-weight:300;
  color:#EEE;
  margin:0;
}
.tooltip {
  z-index:9999;
  pointer-events: none;
}

.widget .widget-header {
  /*overflow-y:hidden;*/
  height: 42px;
  /*border-bottom:none;*/
}

.widget .widget-content  {
  margin-top:-1px;
  width:100%;
}

.widget .widget-header h3 {
  margin-right:0;
}

.widget .widget-header a .fa {
  margin-right:2px;
  margin-left:2px;
}

.widget .widget-header a:hover {
  text-decoration:none;
}

.widget .widget-header a.archive .fa-archive.archived {
  color:#49afcd;
}
.widget .widget-header a.archive .fa-archive {
  color:#BCBCBC;
}

.deleted {
  color: #EDC7C7;
}

.gridStyle {
  height: 500px;
}

h4.ui-pnotify-title {
  font-size:100%;
}

.ui-pnotify-text .progress {
  margin-bottom:0;
}

.ui-pnotify.stack-bottomright {
  right: auto;
  top: auto;
}

.tooltip-inner {
  padding: 5px 10px;
  font-size:11px;
}

.modal.prompt-modal {
  top: 35%;
}

.help-text {
  color: #999;
  font-size: 10px;
  line-height: 12px;
}

.nowrap {
  white-space: nowrap;
}

/* Adding secondary button back */
.btn-secondary:hover, .btn-secondary:focus {
color: #fff;
background-color: #3071a9;
border-color: #285e8e;
}
.btn-secondary:active, .btn-secondary.active, .open>.dropdown-toggle.btn-secondary {
color: #fff;
background-color: #3071a9;
border-color: #285e8e;
}
.btn-secondary {
color: #fff;
background-color: #428bca;
border-color: #357ebd;
}

.hover-table > tbody > tr.hover:hover > td,
.hover-table > tbody > tr.hover:hover > th {
  background-color: rgba(255, 153, 0, 0.35);
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
    -moz-appearance: textfield;
}



/* User Branding */

.img-placeholder {
  position: relative;
  background: #fcfcfc;
  opacity: 0.35;
}

.img-placeholder > *[ng-thumb] {
  max-width: 100%;
  max-height: 150px;
}

.img-placeholder > .progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.5em;
}

.brand-logo {
  height: 170px;
  margin: 10px;
  padding: 10px;
}

.active-logo {
  /*background-color: #F90;*/
  box-shadow: 0 0 16px #F90;
}

.brand-logo .logo-delete{
  right: 12px;
  bottom: 0;
  position: absolute;
}

.sp-replacer {
  border-color: #999;
  border-radius: 5px;
}

/* Utility styles (so we can prioritize style compositions at the view-level) */

.fat-left {
  padding-left: 0.5em;
}

.fat-right {
  padding-right: 0.5em;
}

.fat-top {
  padding-top: 0.5em;
}

.fat-bottom {
  padding-bottom: 0.5em;
}

.fat {
  padding: 0.5em;
}

.bump-left {
  margin-left: 0.5em;
}

.bump-right {
  margin-right: 0.5em;
}

.bump-top {
  margin-top: 0.5em;
}

.bump-bottom {
  margin-bottom: 0.5em;
}

.bump {
  margin: 0.5em;
}

.bump-left-lg {
  margin-left: 1.0em;
}

.bump-right-lg {
  margin-right: 1.0em;
}

.bump-top-lg {
  margin-top: 1.0em;
}

.bump-bottom-lg {
  margin-bottom: 1.0em;
}

.bump-lg {
  margin: 1.0em;
}

.full-width {
  width: 100%;
}

.selected-item {
  border: 2px solid #9ecaed;
  box-shadow: 0 0 5px #ffc555;
}

.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}


.dropdown-menu a.disabled {
    pointer-events: none;
    color: #bbb !important;
}

.lock-button {
    width: 33px;
}

.btn-simulation {
  width: 100px;
}

.unselectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}

.dropdown-revision {
    width: 300px;
}

.dropdown-revision-description {
    padding-left: 0;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dropdown-revision-metadata {
    width: auto;
    float: right;
    padding-right: 0;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dropdown-item {
    padding: 5px 9px;
    margin: 0 6px;
    font-size: 12px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    cursor: default;
    user-select: none;
}

.navbar .navbar-nav > li > .dropdown-revision::after {
    content: unset !important;
}

.designer-design-description {
    cursor: pointer;
}
.designer-design-description span  {
    display: none;
    font-size: 14px;
}

.designer-design-description:hover span{
    display: inline;
}

.credit-card-info {
    font-weight: 800;
}

/* NEW HS WEBSITE */

/* colors */
:root {
  --gray-900: #19202B;
  --gray-600: #707681;
  --gray-400: #C2C6C8;
  --gray-100: #F8F8F8;
  --dark-orange: #FD6030;
  --yellow: #FEC351;
  --orange: #d18a00;
  --blue-gray: #607D8B;
}
/* fonts */
@font-face {
  font-display: 'swap';
  font-family: Roobert;
  font-weight: 300;
  font-style: normal;
  src: url(helioscope/fonts/Roobert-Light.woff2) format('woff2'), url(helioscope/fonts/Roobert-Light.woff) format('woff');
}

@font-face {
  font-display: 'swap';
  font-family: Roobert;
  font-weight: 300;
  font-style: italic;
  src: url(helioscope/fonts/Roobert-LightItalic.woff2) format('woff2'), url(helioscope/fonts/Roobert-LightItalic.woff) format('woff');
}

@font-face {
  font-display: 'swap';
  font-family: Roobert;
  font-weight: 400;
  font-style: normal;
  src: url(helioscope/fonts/Roobert-Regular.woff2) format('woff2'), url(helioscope/fonts/Roobert-Regular.woff) format('woff');
}

@font-face {
  font-display: 'swap';
  font-family: Roobert;
  font-weight: 400;
  font-style: italic;
  src: url(helioscope/fonts/Roobert-RegularItalic.woff2) format('woff2'), url(helioscope/fonts/Roobert-RegularItalic.woff) format('woff');
}

@font-face {
  font-display: 'swap';
  font-family: Roobert;
  font-weight: 500;
  font-style: normal;
  src: url(helioscope/fonts/Roobert-Medium.woff2) format('woff2'), url(helioscope/fonts/Roobert-Medium.woff) format('woff');
}

@font-face {
  font-display: 'swap';
  font-family: Roobert;
  font-weight: 600;
  font-style: normal;
  src: url(helioscope/fonts/Roobert-SemiBold.woff2) format('woff2'), url(helioscope/fonts/Roobert-SemiBold.woff) format('woff');
}

@font-face {
  font-display: 'swap';
  font-family: Roobert;
  font-weight: 700;
  font-style: normal;
  src: url(helioscope/fonts/Roobert-Bold.woff2) format('woff2'), url(helioscope/fonts/Roobert-Bold.woff) format('woff');
}

@font-face {
  font-display: 'swap';
  font-family: 'Roobert Mono';
  font-weight: 400;
  font-style: normal;
  src: url(helioscope/fonts/RoobertMono.woff2) format('woff2'), url(helioscope/fonts/RoobertMono.woff) format('woff');
}

/* navbar */
.nufront .navbar-container {
  border-bottom: 1px solid var(--gray-400);
}

.nufront .navbar-inner {
  display: flex;
  justify-content: space-between;
  padding: 24px 10px !important;
  max-width: 1472px;
  margin: 0 auto !important;
}
.nufront .navbar-inner .nav-cta button-primary {
  margin-left: 10px;
}

.nufront .navbar-inner .nav-cta {
  display: inline-flex;
  justify-content: flex-end;
  grid-gap: 22px;
  gap: 22px;
  max-height: 44px;
}

/* homepage */
.homepage-container {
  padding: 196px 0 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prefooter .col-text span {
  font-family: Roobert, sans-serif;
  font-size: 44px;
  font-weight: 300;
}

.homepage-container .intro-text {
  display: flex;
  flex-wrap: wrap;
  max-width: 856px;
  justify-content: center;
  margin-bottom: 72px;
}
.homepage-container .intro-text .title {
  padding-bottom: 62px;
}

.homepage-container .intro-text h1 {
  font-family: Roobert, sans-serif;
  font-size: 72px;
  font-weight: 300;
  line-height: 90px;
  letter-spacing: 0px;
  text-align: center;
}

@media screen and (max-width:1024px) {
  .homepage-container .intro-text h1 {
    font-size: 72px !important;
    line-height: 90px !important;
  }
}
@media screen and (max-width:767px) {
  .homepage-container .intro-text h1 {
    font-size: 44px !important;
    line-height: 1.17 !important;
  }
}

.homepage-container .intro-text p {
  font-family: Roobert, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.homepage-container .cta {
  width: 50%;
  display: flex;
  justify-content: center;
  gap: 16px;
}

/* login */

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* adjust for header height */
  margin-top: 80px;
}

.login-background {
  background-color: #fff !important;
}

/* signup */

.signup-container {
  padding: 196px 0 125px;
}
.signup-container .min-column-container {
  width: 100% !important;
}

.signup-container .half-section-container .section-text {
  max-width: 562px;
  margin: 0 auto;
  border-left: 1px solid var(--gray-400);
  border-right: 1px solid var(--gray-400);
  padding: 0 48px;
}

.signup-container h1 {
  font-family: Roobert, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 56px;
  line-height: 70px;
  text-align: center;
  color: #010916;
  margin-bottom: 50px;
}

.signup-container input {
  box-shadow: none !important;
  border-radius: 0 !important;
  font-size: 14px !important;
}

.signup-container .dropdown-button {
  box-shadow: none !important;
  background-image: none !important;
  background-color: var(--gray-400);
  text-shadow: none;
  border-color: transparent;
  border-bottom: 1px solid var(--gray-400);
  border-radius: 0;
}
.signup-container #phone .input-group {
  width: 100%;
}
.signup-container #phone .input-group-btn-width {
  width: 0%;
}

.signup-container .terms {
  font-family: Roobert, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  max-width: 100% !important;
  letter-spacing: 0 !important;
}
.signup-container .terms a {
  font-family: Roobert, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  text-decoration: underline;
  letter-spacing: 0 !important;
}

.signup-container .checkbox {
  transform: scale(1);
  min-height: inherit;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 7.5px;
  padding: 0 0 0 20px;
  height: 5px;
}

.signup-container input[type="checkbox"] {
  width: 16px !important;
  height: 16px !important;
  border-radius: none;
}

.signup-container button.login-action {
  float: left;
  border-radius: 0;
  background-image: none;
  background-color: var(--yellow);
  border: 1px solid var(--yellow);
  font-family: Roobert, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #404040;
  transition: all .25s linear;
}
.signup-container button.login-action:hover {
  background-color: var(--orange);
  border: 1px solid var(--orange);
}

/* forms */

.signup-container .form-control,
.modal-dialog .account-container .form-control {
  border: none;
  background-color: var(--gray-100);
  /* Grayscale/Gray 400 */
  color: black;
  box-shadow: none;
  padding-top: 12px 18px;
  height: 44px;
  border-radius: 0 !important;
}

.signup-container .form-control::placeholder,
.modal-dialog .account-container .form-control::placeholder {
  font-family: Roobert, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-600);
}

.signup-container .form-control .control-label,
.modal-dialog .account-container .control-label {
  display: inline-block;
  font-family: Roobert, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: black;
  float: left;
}
.signup-container .form-control:focus,
.modal-dialog .account-container input:focus {
  border-color: var(--gray-400) !important;
}

/* buttons */
.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 12px 16px;
  font-family: Roobert,Helvetica Neue,Helvetica,Arial,sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid var(--yellow);
  background-color: var(--yellow);
  transition: all .25s linear;
  outline: none;
  cursor: pointer;
  color: #404040 !important;
  text-decoration: none !important;
}
.button:hover {
  background-color: var(--orange);
  border-color: var(--orange);
}
.button .chevron, .button .chevron svg {
  width: 16px;
  height: 16px;
}

.button.secondary {
  padding-left: 22px;
  color: #404040;
  background-color: transparent;
  border-color: transparent;
}
.button.secondary:hover .underline:before {
  transform: scaleX(1);
}
.button.secondary .underline {
  position: relative;
}
.button.secondary .underline:before {
  content: " ";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: #404040;
  transform: scaleX(0);
  transform-origin: left;
  transition: all .25s linear;
}

/* prefooter */
.prefooter {
  background: var(--gray-900);
  color: white;
}

.prefooter .col-text span {
  font-family: Roobert, sans-serif;
  font-size: 44px;
  font-weight: 300;
}

@media screen and (max-width:1024px) {
  .prefooter .col-text span {
    font-size: 44px;
    line-height: 90px;
  }
}
@media screen and (max-width:767px) {
  .prefooter .col-text span {
    font-size: 22px;
    line-height: 1.17;
  }
}

.prefooter .prefooter-content {
  padding: 42px 10px;
  margin: 0 auto;
  max-width: 702px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.prefooter .prefooter-content .col-text {
  max-width: 440px;
}

/* footer */
.site-footer {
  padding-top: 48px;
  border-top: 1px solid #fff;
  background-color: var(--gray-900);
}
.site-footer .logo-row {
  border-bottom: 1px solid #4c5562;
}
.site-footer .logo-row .inner-content {
  padding: 24px 122px;
}
.site-footer .inner-content {
  margin: 0 auto;
  max-width: 1440px;
}
.site-footer .menu-row .inner-content {
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
}
.site-footer .menu-row ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex: 0 0 365px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
}
.site-footer .menu-row li {
  display: block;
  margin-bottom: 4px;
}
.site-footer .menu-row li a {
  color: #efefef;
  font-family: Roobert, sans-serif;
  font-size: 12px;
  line-height: 16px;
}
.site-footer .copyright .inner-content {
  padding: 24px 0 48px;
}
.site-footer .copyright p {
  color: #efefef;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: .03em;
  text-align: center;
}

/* modal */

.modal-dialog .account-container {
  border-radius: 0;
  max-width: 480px !important;
  box-shadow: 0px 4px 8px 0px #00000014;
  padding: 32px 32px 32px;
  background: #FFFFFF;
}

.modal-dialog .account-container a {
  font-family: Roobert, sans-serif;
  color: #010916;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}
.modal-dialog .account-container .content,
.modal-dialog .account-container label,
.modal-dialog .account-container p {
  font-family: Roobert, sans-serif;
  color: #010916;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.modal-dialog .account-container .checkbox label {
  line-height: 16px;
}

.modal-dialog .account-container h1 {
  color: #000000;
  font-family: Roobert, sans-serif;
  line-height: 36px;
  margin-bottom: 32px;
}

.modal-dialog .account-container input {
  background-image: none !important;
  background-color: var(--gray-100) !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--gray-400);
  padding: 12px 18px;
  color: var(--gray-600) !important;
  box-shadow: none;
  border-radius: 0;
  font-family: Roobert, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.modal-dialog .account-container button.login-action {
  background: var(--yellow);
  color: #010916;
  font-family: Roobert, sans-serif;
  border-radius: 0;
  border-color: var(--yellow);
  font-size: 14px;
  font-weight: 400;
  transition: all .25s linear;
  height: 44px;
}
.modal-dialog .account-container button.login-action:hover {
  background: var(--orange);
  border: 1px solid var(--orange);
}

.independent-tilt-alert {
  font-size: 12px;
  padding: 5px;
  margin: 8px 0;
}

.independent-tilt-alert .badge {
  /* make badge appear circular for this icon */
  height: 19px;
  width: 19px;
  margin-right: 2px;
}

.menu-callout {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 6px;
  font-size: 12px;
  padding: 6px 8px;
  margin: 8px 0;
}

.menu-callout .callout-description {
  display: flex;
  flex-direction: column
}

.menu-callout span.callout-header-text {
    font-weight: 700;
    line-height: normal;
}

.menu-callout span.callout-text {
    line-height: normal;
}

.dropdown-hint {
  color: #999;
  /* accounts for left icon width */
  padding-left: 14px;
}


/** custom css overrides for the async progress bar **/
.async-loader-progress-bar {
  padding-top: 10px;
}

.async-loader-progress-bar .progress {
  height: 8px;
}
